@media screen and (max-width: 1250px) {
  .container-center {
    max-width: 1050px;
  }

  .video-modal iframe {
    height: calc((100vw - 220px) * 9 / 16);
  }

  .top-news a {
    width: 32%;
  }

  .top-news a .item-new img {
    height: 100%;
    width: auto;
  }

  .main-menu li a {
    font-size: 14px;
    padding: 20px 10px;
  }

  .persons__content {
    padding: 10px;
  }

  .persons {
    grid-template-columns: repeat(3, 1fr);
  }

  .matches {
    padding: 0 35px;
  }

  .slides {
    padding: 0 35px;
  }

  .arrows__item {
    right: 0;
  }

  .arrows__item--right {
    left: 0;
    right: auto;
  }

  .media__container {
    padding: 20px;
  }

  .trophies {
    grid-template-columns: repeat(4, 1fr);
  }

  .phead {
    gap: 8px;
    grid-template-columns: calc(66% - 4px) calc(34% - 4px);
  }

  .phead__content {
    gap: 30px;
  }

  .phead__container {
    padding: 60px 100px 45px 30px;
    gap: 25px;
  }

  .phead__right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 33% 100%, 0% -9%);
  }

  .phead__number {
    font-size: 144px;
    line-height: 176px;
  }

  .phead__text {
    font-size: 60px;
    line-height: 70px;
  }
}

@media screen and (max-width: 1150px) {
  .phead__right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 32% 100%, 0% -10%);
  }

  .phead__container {
    padding: 50px 90px 35px 25px;
  }

  .phead__img {
    max-width: 250px;
    max-height: 250px;
  }

  .pbutton {
    gap: 35px;
  }

  .gap--30 {
    gap: 25px;
  }
}

@media screen and (max-width: 1050px) {
  .container-center {
    max-width: none;
    margin: auto;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }

  .persons {
    gap: 20px 10px;
  }

  .header-top .block-top-games {
    display: grid;
    grid-template-columns: calc((100% - 190px) / 2) 190px calc(
        (100% - 190px) / 2
      );
  }

  .header-top .top-game {
    width: 100%;
    margin-bottom: 15px;
  }

  .matches__content {
    padding: 15px 25px;
  }

  .matches__point {
    font-size: 26px;
    line-height: 30px;
  }

  .media__name {
    font-size: 24px;
    line-height: 26px;
  }

  .trophies__sum {
    font-size: 36px;
    line-height: 40px;
  }

  .birthdate__number {
    font-size: 44px;
    line-height: 44px;
  }

  .phead {
    gap: 15px;
    grid-template-columns: calc(60% - 10px) calc(40% - 5px);
  }

  .phead__container {
    clip-path: none;
    margin: 0;
    padding: 50px 25px 25px 25px;
    gap: 20px;
  }

  .phead__content {
    gap: 25px;
  }

  .phead__right {
    clip-path: none;
    margin: 0;
  }

  .phead__list {
    transform: none;
    padding: 40px 25px;
  }

  .phead__item {
    transform: none;
  }

  .phead__number {
    font-size: 150px;
    line-height: 202px;
  }

  .phead__text {
    font-size: 54px;
    line-height: 66px;
  }

  .phead__title {
    font-size: 30px;
    line-height: 34px;
  }

  .player {
    gap: 50px;
    padding: 30px 0 60px 0;
  }
}

@media screen and (max-width: 950px) {
  .header-logo {
    display: none !important;
  }

  .mobil-logo {
    display: block;
    text-align: center;
  }

  .header-top .block-top-games {
    text-align: center;
    display: block;
  }

  .header-top .change-team {
    margin: 15px auto 0;
    width: 803px;
  }

  header .top-red {
    height: auto;
    padding: 0 0 7px;
  }

  header .top-red .left,
  header .top-red .right {
    float: none;
    display: block;
    width: auto;
    text-align: center;
    padding: 5px 0;
  }

  .persons {
    grid-template-columns: repeat(2, 1fr);
  }

  .matches__content {
    padding: 15px 20px;
  }

  .matches__point {
    font-size: 24px;
    line-height: 28px;
  }

  .tables {
    grid-template-columns: 100%;
    gap: 0;
  }

  .buttons__navigation {
    gap: 25px;
  }

  .media__info {
    font-size: 18px;
    line-height: 22px;
  }

  .media__date {
    font-size: 18px;
    line-height: 22px;
  }

  .media__name {
    font-size: 22px;
    line-height: 26px;
  }

  .media__preview {
    grid-template-columns: 100%;
  }

  .trophies__sum {
    font-size: 32px;
    line-height: 36px;
  }

  .birthdate__name {
    font-size: 20px;
    line-height: 24px;
  }

  .birthdate__role {
    font-size: 20px;
    line-height: 24px;
  }

  .birthdate__date {
    font-size: 22px;
    line-height: 26px;
  }

  .birthdate__number {
    font-size: 38px;
    line-height: 38px;
  }

  .birthdate {
    gap: 25px;
  }

  .mobil-show {
    display: block;
  }

  .mobile-hide {
    display: none;
  }

  .phead {
    gap: 10px;
    margin: 0 auto;
    grid-template-columns: calc(60% - 5px) calc(40% - 5px);
  }

  .phead__container {
    gap: 10px;
    padding: 25px 20px;
  }

  .phead__img {
    max-width: 230px;
    max-height: 230px;
    margin: 0 auto;
  }

  .phead__content {
    gap: 20px;
  }

  .phead__item {
    width: auto;
  }

  .phead__number {
    font-size: 114px;
    line-height: 136px;
  }

  .phead__text {
    font-size: 42px;
    line-height: 46px;
  }

  .pbutton__navigation {
    gap: 20px;
  }

  .pbutton__link {
    font-size: 20px;
    line-height: 24px;
  }

  .phead__list {
    gap: 20px;
    padding: 30px 20px;
  }

  .table-scroll .table * {
    width: max-content;
  }

  .pbutton {
    gap: 30px;
  }

  .gap--30 {
    gap: 20px;
  }

  .ReactModal__Content {
    inset: 30px !important;
  }

  .video-modal iframe {
    height: calc((100vw - 210px) * 9 / 16);
  }
}

@media screen and (max-width: 802px) {
  .header-top .change-team {
    text-align: center;
    width: auto;
  }

  .header-top .top-game {
    margin-bottom: 10px;
  }

  .deep-block .container-center {
    flex-wrap: wrap;
  }

  .deep-block .photo-block {
    display: block;
    width: auto;
  }

  .deep-block .photo-block .photo-list-main {
    width: 100%;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: stretch;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .photo-list-main li:nth-child(3n + 3),
  .photo-list-main li {
    margin: 10px auto;
  }

  .birthdays-block,
  .promo-block {
    display: block;
    width: 100%;
    margin: 30px 0;
    text-align: center;
  }

  .birthdays-list {
    width: 280px;
    margin: 0 auto;
    text-align: left;
  }

  .content h2.title-h {
    text-align: center;
  }

  .content h2.title-h::after {
    left: calc(50% - 35px);
  }

  .content .block-press .all-news,
  .content .block-video .all-news {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 10px 0;
    margin: 0 0 30px;
  }

  .top-news {
    flex-wrap: wrap;
  }

  .top-news a {
    display: block;
    width: calc(100% - 20px);
    margin: 10px;
  }

  .top-news a .item-new img {
    height: auto;
    width: 110%;
  }

  .promo-banner img {
    width: 100%;
    height: auto;
  }

  .content .articles-list li {
    width: auto;
    margin: 20px auto;
    display: block;
  }

  .block-trophy .container-center {
    flex-wrap: wrap;
  }

  .block-trophy .item {
    margin: 15px auto;
  }

  .block-video .video-list {
    flex-wrap: wrap;
  }

  .block-video .video-list li {
    display: block;
    width: 100%;
    margin: 20px auto;
    text-align: center;
  }

  .content .stats .drop-sites {
    position: relative;
    top: 0;
    right: 0;
    margin: 5px auto;
    text-align: center;
    display: block;
    width: 200px;
  }

  .content .stats .drop-sites .drop-sites-layer {
    width: 204px;
    text-align: left;
  }

  .content .stats h3 {
    text-align: center;
  }

  .main-menu {
    position: relative;
    background: none;
    box-shadow: none;
  }

  .main-menu ul {
    display: none;
    position: absolute;
    top: 30px;
    z-index: 12;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .main-menu ul li {
    border: 0;
    border-bottom: 1px #b8c1c1 solid;
    position: relative;
  }

  .main-menu ul.active {
    display: block;
  }

  .main-menu ul li ul,
  .main-menu li:last-child ul {
    left: 30px;
    width: auto;
    padding: 10px 0;
    right: 0px;
  }

  .main-menu li ul li a {
    padding: 10px;
  }

  #mobmenu {
    display: block;
  }

  #mobmenu.active {
    color: #992a21;
  }

  main,
  aside {
    display: block;
    width: 100%;
    float: none;
  }

  main {
    padding-right: 0;
  }

  aside {
    padding-left: 0;
    margin-top: 0;
  }

  .content .articles-list {
    display: block;
    width: auto;
  }

  .sale-form .title_table {
    width: 100%;
    text-align: center;
    display: block;
  }

  .sale-form li {
    width: 100%;
    display: block;
    text-align: center;
    float: none;
  }

  .sale-form textarea {
    width: calc(100% - 20px);
  }

  .slider .img-slide img {
    height: 100%;
    width: auto;
  }

  .logo-sm {
    width: 100%;
    display: block;
    height: 90px;
    padding: 15px 0 5px;
    bottom: 0;
    text-align: center;
    position: relative;
  }

  .logo-sm img {
    width: 90px;
    height: auto;
  }

  .title-block h1 {
    display: block;
    width: 100%;
    text-align: center;
  }

  .title-block {
    display: block;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .title-block h1::after {
    content: "";
    position: absolute;
    width: 120px;
    height: 5px;
    bottom: 0;
    left: calc(50% - 60px);
  }

  .content .purchases-list li {
    display: block;
  }

  .content .purchases-list li .title,
  .content .purchases-list li .number,
  .content .purchases-list li .date,
  .content .purchases-list li .files,
  .content .purchases-list li .desc {
    display: block;
    vertical-align: top;
    text-align: center;
    width: 100%;
    padding: 6px 0;
  }

  header.main {
    background-color: #14273a;
  }

  .slider {
    margin-top: -40px;
  }

  .title-block .breadcrumbs-block {
    text-align: center !important;
    display: block;
    padding-left: 30px;
  }

  .block-wellcome img {
    width: 100%;
    height: auto;
  }

  div.error {
    right: 0;
    top: 100%;
    width: 150px;
  }

  .content .stats .stat-table .logo {
    display: none;
  }

  .content .stats h3 span {
    float: none;
  }

  .photo-list-other {
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
  }

  .photo-list-other li {
    width: 80%;
    text-align: center;
  }

  .block-video.other .video-list {
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
  }

  .block-video.other .video-list li {
    width: 80%;
    text-align: center;
  }

  .content .detail .easy-text.stat-colums {
    width: auto;
    display: block;
    margin-right: 0;
  }

  .header-top .top-game.nonext {
    width: 100%;
  }

  .persons {
    grid-template-columns: repeat(3, 1fr);
  }

  .matches__top {
    font-size: 16px;
    line-height: 18px;
  }

  .matches__content {
    padding: 15px;
  }

  .matches__teams {
    gap: 10px;
  }

  .matches__name {
    font-size: 18px;
    line-height: 22px;
  }

  .matches__point {
    font-size: 22px;
    line-height: 26px;
  }

  .arrows__item {
    top: calc(50% - 55px);
  }

  .buttons__navigation {
    gap: 20px;
  }

  .media__info {
    font-size: 16px;
    line-height: 20px;
  }

  .media__date {
    font-size: 16px;
    line-height: 20px;
  }

  .media__name {
    font-size: 20px;
    line-height: 24px;
  }

  .trophies__sum {
    font-size: 28px;
    line-height: 32px;
  }

  .trophies {
    grid-template-columns: repeat(3, 1fr);
  }

  .birthdate__name {
    font-size: 18px;
    line-height: 22px;
  }

  .birthdate__role {
    font-size: 18px;
    line-height: 22px;
  }

  .birthdate__date {
    font-size: 20px;
    line-height: 24px;
  }

  .birthdate__number {
    font-size: 32px;
    line-height: 32px;
  }

  .birthdate {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .birthdate__content {
    padding: 15px;
  }

  .birthdate__row {
    gap: 10px;
  }

  .player {
    gap: 40px;
    padding: 30px 0 40px 0;
  }

  .phead__container {
    gap: 15px;
  }

  .phead {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .phead__content {
    justify-content: center;
    gap: 15px;
  }

  .phead__subtitle {
    font-size: 24px;
    line-height: 28px;
  }

  .phead__title {
    font-size: 30px;
    line-height: 34px;
  }

  .pbutton__link {
    font-size: 18px;
    line-height: 22px;
  }

  .phead__list {
    padding: 25px 15px;
  }

  .pbutton {
    gap: 25px;
  }

  .gap--30 {
    gap: 15px;
  }

  .gap--20 {
    gap: 15px;
  }

  .ReactModal__Content {
    inset: 20px !important;
    padding: 15px !important;
  }

  .video-modal iframe {
    height: calc((100vw - 165px) * 9 / 16);
  }

  .sidebar-banner {
    width: max-content;
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .persons {
    grid-template-columns: repeat(2, 1fr);
  }

  .matches__point {
    font-size: 20px;
    line-height: 24px;
  }

  .filters {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 30px) / 3));
  }

  .filters__item {
    width: 100%;
  }

  .buttons__navigation {
    gap: 15px;
  }

  .media__name {
    font-size: 18px;
    line-height: 22px;
  }

  .media__container {
    padding: 15px 10px;
    gap: 15px;
  }

  .media__play {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }

  .trophies__sum {
    font-size: 24px;
    line-height: 28px;
  }

  .trophies {
    grid-template-columns: repeat(2, 1fr);
  }

  .birthdate__name {
    font-size: 16px;
    line-height: 20px;
  }

  .birthdate__role {
    font-size: 16px;
    line-height: 20px;
  }

  .birthdate__date {
    font-size: 18px;
    line-height: 22px;
  }

  .birthdate__number {
    font-size: 26px;
    line-height: 26px;
  }

  .birthdate {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }

  .phead__number {
    font-size: 84px;
    line-height: 106px;
    -webkit-text-stroke: 0;
  }

  .phead__text {
    font-size: 34px;
    line-height: 38px;
  }

  .phead__subtitle {
    font-size: 22px;
    line-height: 26px;
  }

  .phead__title {
    font-size: 28px;
    line-height: 32px;
  }

  .phead__img {
    max-width: 200px;
    max-height: 200px;
  }

  .pbutton__navigation {
    gap: 10px;
  }

  .pbutton__link {
    padding: 5px 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .pbutton__link {
    font-size: 16px;
    line-height: 20px;
  }

  .ReactModal__Content {
    inset: 10px !important;
  }

  .video-modal iframe {
    height: calc((100vw - 115px) * 9 / 16);
  }
}

@media screen and (max-width: 470px) {
  .top-news a .item-new img {
    height: 100%;
    width: auto;
  }

  .header-top .top-game {
    width: 100%;
  }

  .header-top .top-game .team .team-logo img {
    width: 60px;
    height: auto;
    position: relative;
    top: 5px;
  }

  .header-top .top-game .team .team-title {
    display: none;
  }

  .header-top .top-game .desc {
    font-size: 14px;
  }

  .header-top .top-game .count b {
    font-size: 30px;
  }

  header .top-red .left a {
    display: inline-block;
    width: auto;
    padding-top: 5px;
    margin-right: 0;
  }

  .content .matches-list li .game-info .teams p img {
    display: none;
  }

  .navigation a:not(:last-child):not(:first-child):not(.active) {
    display: none;
  }

  .navigation a,
  .navigation span {
    display: block;
    width: 50%;
    margin: 5px auto;
  }

  .photo-list-other {
    display: block;
  }

  .photo-list-other li {
    width: 100%;
    display: block;
  }

  .matches__top {
    font-size: 14px;
    line-height: 16px;
  }

  .matches__content {
    padding: 10px;
    gap: 5px;
  }

  .matches__teams {
    gap: 5px;
  }

  .matches__name {
    font-size: 16px;
    line-height: 20px;
  }

  .matches__point {
    font-size: 18px;
    line-height: 22px;
  }

  .arrows {
    display: none;
  }

  .matches {
    padding: 0;
  }

  .slides {
    padding: 0;
  }

  .filters {
    gap: 10px;
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
  }

  .buttons__navigation {
    gap: 10px;
  }

  .media__text {
    font-size: 12px;
    line-height: 14px;
  }

  .media__info {
    font-size: 14px;
    line-height: 18px;
  }

  .media__date {
    font-size: 14px;
    line-height: 18px;
  }

  .media__name {
    font-size: 16px;
    line-height: 20px;
  }

  .media__play {
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }

  .slides__content {
    gap: 10px;
  }

  .slides__link {
    gap: 15px;
  }

  .slides__play {
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }

  .trophies__name {
    font-size: 14px;
    line-height: 18px;
  }

  .trophies__sum {
    font-size: 20px;
    line-height: 24px;
  }

  .block-trophy .container-center {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .birthdate__name {
    font-size: 14px;
    line-height: 18px;
  }

  .birthdate__role {
    font-size: 14px;
    line-height: 18px;
  }

  .birthdate__date {
    font-size: 16px;
    line-height: 20px;
  }

  .birthdate__number {
    font-size: 20px;
    line-height: 20px;
  }

  .birthdate {
    gap: 10px;
  }

  .birthdate__content {
    padding: 15px 10px;
    gap: 10px;
  }

  .birthdate__row {
    gap: 5px;
  }

  .player {
    gap: 30px;
    padding: 30px 0;
  }

  .phead__list {
    padding: 20px 10px;
  }

  .phead__content {
    gap: 10px;
  }

  .phead__number {
    font-size: 54px;
    line-height: 76px;
    text-shadow: -1px 0 var(--white), -1px 0 var(--white), 0 1px var(--white),
      0 -1px var(--white), 1px 1px var(--white), -1px -1px var(--white),
      1px -1px var(--white), -1px 1px var(--white);
  }

  .phead__text {
    font-size: 26px;
    line-height: 30px;
  }

  .phead__subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .phead__title {
    font-size: 26px;
    line-height: 30px;
  }

  .phead__img {
    max-width: 190px;
    max-height: 190px;
  }

  .pbutton__navigation {
    gap: 0;
  }

  .pbutton__link {
    font-size: 14px;
    line-height: 18px;
  }

  .pbutton {
    gap: 20px;
  }

  .gap--30 {
    gap: 10px;
  }

  .gap--20 {
    gap: 10px;
  }

  .ReactModal__Content {
    inset: 0 !important;
    padding: 10px !important;
  }

  .video-modal iframe {
    height: calc((100vw - 70px) * 9 / 16);
  }

  .video-modal__icon {
    font-size: 30px;
  }

  .team-additional {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 370px) {
  .persons {
    grid-template-columns: repeat(1, 1fr);
  }

  .filters {
    grid-template-columns: 100%;
  }

  .trophies {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 320px) {
}

/*end media scren 320*/
/*хаки для IE9-10*/

@media screen and (min-width: 0\0) {
}

/*конец хаки для IE9-10*/
